import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { 
  Grid, 
  Modal, 
  Header, 
  Button, 
  Divider, 
  Table,
  Message,
  Form,
  Menu,
  Input,
  Segment
} from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import ReeLaAPI from "../util/reela_lib";

// import config
import { config } from '../config';
import moment from "moment";
import { displayPrice, formatDate } from "../util/tools";
import Unauthorized from "../framework/unauthorized";
import OrderProductRow from "./item_row";

const OrderDetails = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  let { orderUuid } = useParams<any>();

  // states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<any>(null);

  const [screenLayout, setScreenLayout] = useState<'table'|'cards'|null>(null);

  const [isLoadingDrivers, setLoadingDrivers] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<any>(false);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isGeneratingInvoice, setIsGeneratingInvoice] = useState<boolean>(false);

  const [editMode, setEditMode] = useState<boolean>(false);
  
  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;
  let vendor:boolean = false;
  let driver:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');

    if(profile.type === 'management') management = true;
    if(profile.type === 'vendor') vendor = true;
    if(profile.type === 'driver') driver = true;
  }
  catch(e) {
    console.warn('Error parsing the profile', e);
    let profile:any = null;
  }

  useEffect(() => {
    // set default view
    if(!screenLayout) {
      if(window.innerWidth <= 768) setScreenLayout('cards');
      else setScreenLayout('table');
    }
    
    if(management) getDrivers();
    
    getOrder();
  }, []);

  const getDrivers = async() => {
    // get the registrars in the system
    if(isLoadingDrivers) return null;

    setLoadingDrivers(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.debug = process.env.REACT_APP_MODE === 'development' ? true : false;
    reelaApi.type = 'driver';
    
    var res:any = await reelaApi.getUsers();
    setLoadingDrivers(false);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });

      return null;
    } 

    var organizedDrivers:any = [];

    res.data.forEach((driver:any, key:number) => {
      organizedDrivers.push({
        key: driver.id,
        text: `(${driver.id}) ${driver.first_name} ${driver.last_name} - ${driver.phone}`,
        value: driver.id
      })
    });

    setDrivers(organizedDrivers);
    return null;
  }
  
  const getOrder = async () => {
    setIsLoading(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.orderUuid = orderUuid;

    var res:any = await reelaApi.getOrder();

    if(!res || !res.data) {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [
          { content: t('g.ok'), onClick: () => navigate(-1) }
        ]
      });

      setIsLoading(false);
      return null;
    }

    var retrievedOrder = res.data;

    // set the order strings to objects where needed
    var customerDetails:any = retrievedOrder?.order?.customer_details;

    if(customerDetails && typeof customerDetails === 'string') {
      try {
        customerDetails = JSON.parse(customerDetails);
  
        retrievedOrder = {
          ...retrievedOrder,
          order: {
            ...retrievedOrder.order,
            customer_details: customerDetails
          }
        }
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') console.error(e);
      }
    }

    setOrder(retrievedOrder);
    setIsLoading(false);
    return true;
  }
  
  const generateInvoice = async () => {
    if(isGeneratingInvoice) return null;
    setIsGeneratingInvoice(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.orderUuid = orderUuid;
    
    var res:any = await reelaApi.generateOrderInvice();

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });

      return null;
    }
    
    if(res.data?.content) window.open(res.data.content, '_blank');
    
    setIsGeneratingInvoice(false);
    return null;
  }
  
  const completeDelivery = async () => {
    // update order
    if(isProcessing) return null;
    setIsProcessing(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.orderUuid = orderUuid;

    var res:any = await reelaApi.completeOrderDelivery();
    setIsProcessing(false);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });

      return null;
    }

    getOrder();
    
    setModal({
      visible: true,
      title: t("g.processCompleted"),
      message: t("g.processCompletedMessage"),
      actions: [{ content: t('g.ok') }]
    });
  }

  // deleteOrder defualt is 
  const deleteOrder = async (hard:boolean=false, cartRedirect:boolean=false) => {
    // Delete order
    if(isProcessing) return null;
    setIsProcessing(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.orderUuid = orderUuid;
    reelaApi.hardDelete = hard; 

    var res:any = await reelaApi.deleteOrder();
    setIsProcessing(false);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });
    }
    else{
      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ content: t('g.ok') }]
      });
      
      if(cartRedirect) {
        navigate(`/carts/form/${orderUuid}`);
      }
      else{
        navigate(`/orders`);
      }
    }
    
    return null;
  }
  
  // this is to update the assigned driver or Cash collected
  const updateOrder = async () => {
    // update order
    if(isProcessing) return null;
    setIsProcessing(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.orderUuid = orderUuid;
    reelaApi.driverId = order.order.driver_id;
    reelaApi.status = order.order.status;

    var res:any = await reelaApi.updateOrder();
    setIsProcessing(false);
    
    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });

      return null;
    }

    getOrder();
    
    setModal({
      visible: true,
      title: t("g.processCompleted"),
      message: t("g.processCompletedMessage"),
      actions: [{ content: t('g.ok') }]
    });

  }

  const _renderProducts = () => {
    if(!order || !order.items || order.items.length < 1) 
      return null;

    var items:Array<any> = order.items;
    var renderItems:Array<any> = [];

    // loop the products
    items.forEach( (item:any, key:number) => {
      renderItems.push(
        <OrderProductRow
          product={item}
          management={management}
          vendor={vendor}
          orderStatus={order?.order?.status}
          editMode={editMode}
          orderUuid={orderUuid}
          screenLayout={screenLayout}
          setModal={setModal}
          getOrder={getOrder}
        />
      )
    });

    return <>{renderItems}</>
  }

  const _renderPaymentHistory = () => {
    if(!order || !order.payments || order.payments < 1) {
      return null;
    }

    var render:any = [];

    order.payments.forEach((payment:any, key:number) => {
      var success = false;
      var fail = false;

      if(payment.transaction_status === 'success') {
        success = true;
      }
      else {
        fail = true;
      }

      render.push(
        <Table.Row positive={success} negative={fail}>
          <Table.Cell>#{payment.uuid.substr(payment.uuid.length - 5)}</Table.Cell>
          <Table.Cell>{payment.transaction_status}</Table.Cell>
          <Table.Cell>{payment.amount ? payment.amount.toFixed(2) : '-'} {t(`c.${localStorage.getItem('currency-key')}`)}</Table.Cell>
          <Table.Cell>{moment(payment.created_at).fromNow()}</Table.Cell>
        </Table.Row>
      )
    })

    return render;
  }

  const _renderCashCollectingMessage = () => {
    if(!management && !driver) return null;

    if(!order) return null;
    if(
      order.order.status !== 'new' &&
      order.order.status !== 'dispatched' &&
      order.order.status !== 'preparing' &&
      order.order.status !== 'in route'
    ) return null;

    if(order.order.payment_method !== 'cash') return null;

    return <>
      <Divider />

      <Message color='red'>
        <Message.Header>
          {t('s.orders.collectCash')} - {displayPrice(order.order.grand_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
        </Message.Header>
        <Message.Content>
          {t('s.orders.collectCashDescription')} {displayPrice(order.order.grand_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
        </Message.Content>
      </Message>
    </> 
  }

  const _renderCompleteDeliveryButton = () => {
    if(!management && !driver) return null;

    if(!order) return null;
    
    if(
      order.order.status !== 'new' &&
      order.order.status !== 'dispatched' &&
      order.order.status !== 'preparing' &&
      order.order.status !== 'in route'
    ) return null;

    if(order.order.payment_method !== 'cash') return null;

    return <>
      <Divider hidden />
      
      <Button
        fluid
        primary
        loading={isProcessing}
        content={t('s.orders.completeDelivery')}
        onClick={() => setModal({
          visible: true,
          title: t('g.areYouSure'),
          message: t('s.orders.completeDeliveryConfirmation'),
          actions: [
            { content: t('g.confirm'), positive: true, onClick: () => completeDelivery()},
            { content: t('g.cancel') }
          ]
        })}
      />
    </>
  }

  // handle unauthorized users
  if(!management && !driver && !vendor) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  // handle no order set
  if(!order) {
    return <InternalPage loading={isLoading}>
      <div>{t('g.noResults')}</div>
    </InternalPage>
  }

  return <InternalPage loading={isLoading}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1' width={12} inline>
      {t('s.orders.orderDetails')}
      <Header.Subheader>{t('s.orders.order')} # {orderUuid}</Header.Subheader>
    </Header>

    <Divider hidden />

    <Grid columns={1}>
      <Grid.Row>
        <Grid.Column>
          <Menu>
            <Menu.Item
              name='View Invoice'
              onClick={generateInvoice}
            >
              View Invoice
            </Menu.Item>
            <Menu.Item
              name='SMS Invoice'
              onClick={async () => {
                var reelaApi:any = new ReeLaAPI;
                reelaApi.baseAPI = localStorage.getItem('domain_target');
                reelaApi.authToken = localStorage.getItem('auth_token');
                reelaApi.orderUuid = orderUuid;
                reelaApi.type = 'phone';

                var res:any = await reelaApi.sendInvoice();
                
                if(res && res.code === 200) {
                  setModal({
                    visible: true,
                    title: t("g.processCompleted"),
                    message: t("g.processCompletedMessage"),
                    actions: [{ content: t('g.ok') }]
                  });
            
                  return null;
                }
                else {
                  setModal({
                    visible: true,
                    title: t("g.failedToProcessRequest"),
                    message: reelaApi.getErrorsString(),
                    actions: [{ content: t('g.ok') }]
                  });
            
                  return null;
                }
              }}
            >
              SMS Invoice
            </Menu.Item>
            <Menu.Item
              name='Email Invoice'
              onClick={async () => {
                var reelaApi:any = new ReeLaAPI;
                reelaApi.baseAPI = localStorage.getItem('domain_target');
                reelaApi.authToken = localStorage.getItem('auth_token');
                reelaApi.orderUuid = orderUuid;
                reelaApi.type = 'email';

                var res:any = await reelaApi.sendInvoice();
                
                if(res && res.code === 200) {
                  setModal({
                    visible: true,
                    title: t("g.processCompleted"),
                    message: t("g.processCompletedMessage"),
                    actions: [{ content: t('g.ok') }]
                  });
            
                  return null;
                }
                else {
                  setModal({
                    visible: true,
                    title: t("g.failedToProcessRequest"),
                    message: reelaApi.getErrorsString(),
                    actions: [{ content: t('g.ok') }]
                  });
            
                  return null;
                }
              }}
            >
              Email Invoice
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden />

    {management && <>
      <Form onSubmit={() => updateOrder()}>
        <Header as='h1'>
          {t('g.status')}
          <Header.Subheader>{t('s.orders.updateOrderStatus')}</Header.Subheader>
        </Header>
        <Form.Group>
          <Form.Dropdown
            width={14}
            placeholder={t('s.orders.updateStatus') || 'Update Status'}
            fluid
            selection
            value={order.order.status}
            options={[
              {text:'New', value:'new'},
              {text:'Preparing', value:'preparing'},
              {text:'Dispatched', value:'dispatched'},
              {text:'In Route', value:'in route'},
              {text:'Delivered', value:'delivered'},
              {text:'Cancelled', value:'cancelled'},
            ]}
            onChange={(e, { name, value }) => setOrder({
              ...order,
              order: {
                ...order.order,
                status: value||null
              }
            })}
          />
          <Form.Button
            width={2}
            fluid
            content={t('g.update')}
            type='submit'
          />
        </Form.Group>
      </Form>

      <Divider />

      <Form onSubmit={() => updateOrder()}>
        <Header 
          as='h2'
          content={t('s.orders.assignDriver')}
          subheader={t('s.orders.assignDriverDescription')}
        />
        <Form.Group widths={16}>
          <Form.Dropdown
            width={14}
            loading={isLoadingDrivers}
            placeholder={t('g.selectOne') || 'Select One'}
            fluid
            selection
            options={drivers}
            value={order.order.driver_id}
            onChange={(e, { name, value }) => setOrder({
              ...order,
              order: {
                ...order.order,
                driver_id: value||null
              }
            })}
          />
          <Form.Button
            width={2}
            fluid
            content={t('g.assign')}
            type='submit'
          />
        </Form.Group>
      </Form>
    </> }

    {(management || driver) && <>
      <Divider />
      
      <Header as='h2' content={t('s.orders.customerInformation')} />

      <Grid stackable divided>
        <Grid.Row>
          <Grid.Column width={8}>
            <Grid >
              <Grid.Row>
                <Grid.Column width={6}>{t('g.phone')}:</Grid.Column>
                <Grid.Column width={10} textAlign='right'>
                  {order?.order?.customer_details?.country_code}{order?.order?.customer_details?.phone}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>{t('g.email')}:</Grid.Column>
                <Grid.Column width={10} textAlign='right'>
                  {order?.order?.customer_details?.email}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid >
              <Grid.Row>
                <Grid.Column width={6}>{t('g.name')}:</Grid.Column>
                <Grid.Column width={10} textAlign='right'>
                  {order?.order?.customer_details?.name_local && order?.order?.customer_details?.name_local[localStorage.getItem('language') || 'en']}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>{t('s.customers.licenseNumber')}:</Grid.Column>
                <Grid.Column width={10} textAlign='right'>
                  {order?.order?.customer_details?.license_number}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>{t('s.customers.taxId')}:</Grid.Column>
                <Grid.Column width={10} textAlign='right'>
                  {order?.order?.customer_details?.tax_id}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>{t('g.address')}:</Grid.Column>
                <Grid.Column width={10} textAlign='right'>
                  {order?.order?.customer_details?.address_1}<br />
                  {order?.order?.customer_details?.city} {order?.order?.customer_details?.state}<br />
                  {order?.order?.customer_details?.country}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />

      <Button
        fluid
        primary
        content={t('s.orders.deliveryLocation')}
        icon='map'
        labelPosition="left"
        onClick={() => {
          var coords = order?.order?.customer_details?.location;
          
          if(typeof coords === 'string') {
            try { coords = JSON.parse(coords) }
            catch(e) {
              if(process.env.NODE_MODE === 'development') console.warn('Failed to parse coords', coords, e);
              coords = null;  
            }
          }

          if(coords && coords.latitude && coords.longitude) window.open(
            `https://www.google.com/maps/search/?api=1&query=${coords.latitude}%2C${coords.longitude}`,
            '_blank'
          );

          else alert('Sorry! No valid coords location found.');
        }}
      />
    </>}

    <Divider />

    <Header as='h2' content={t('s.orders.order')} />

    <Grid >
      <Grid.Row>
        <Grid.Column width={6}>{t('g.status')}:</Grid.Column>
        <Grid.Column width={10} textAlign='right'>
          <div style={{textTransform: 'capitalize'}}>  
            {order.order.status}
          </div>
        </Grid.Column>
      </Grid.Row>
      {(management || driver) && <>
        <Grid.Row>
          <Grid.Column width={6}>{`${t('g.registrar')} ${t('g.id')}`}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {order.order.registrar_id}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{`${t('g.registrar')} ${t('g.name')}`}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {`${order.order.registrar_first_name || ''} ${order.order.registrar_last_name || ''}`}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('g.paid')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {order.order.paid === 'y' ? t('g.yes') : t('g.no')}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.deliveryDate')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {formatDate(order.order.delivery_date)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.deliveryShift')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {order.order.delivery_shift}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.cashCollected')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {order.order.cash_collected === 'y' ? t('g.yes') : t('g.no')}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.subTotal')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(order.order.sub_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.vendorsTax')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(order.order.vendors_tax_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.shippingTotal')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(order.order.shipping_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.serviceFee')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(order.order.service_fee)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.houseTax')} ({order.order.house_tax_value * 100}%):</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(order.order.house_tax_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.coupon')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {order.order.coupon}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.discount')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(order.order.discount)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.grandTotal')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(order.order.grand_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
      </>}
    </Grid>

    <Divider />

    <Grid verticalAlign='middle'>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as='h3' content={t('s.products.title')} />
        </Grid.Column>
        {
          (
            !["cancelled", "delivered"].includes(order.order.status) ||
            !["cash"].includes(order.order.payment_method) 
          ) &&
          management
          &&
          <Grid.Column textAlign='right'>
            <Button 
              primary={!editMode ? true : undefined}
              negative={!editMode ? undefined : true}
              content={!editMode ? t("g.edit") : t("g.cancel")}
              labelPosition={!editMode ? 'left' : undefined}
              icon={!editMode ? 'edit' : null}
              onClick={() => 
                setModal({
                  visible: true,
                  title: t("g.areYouSure"),
                  message: t("s.orders.addProductsToOrderDescription"),
                  actions: [
                    { 
                      key: 0,
                      content: t('g.cancel'),
                      onClick: () => null
                    },
                    { 
                      key: 1,
                      content: `${t('g.goTo')} ${t('s.carts.cart')}`,
                      color: 'yellow',
                      onClick: async () => {
                        deleteOrder(true, true);
                      }
                    },
                  ]
                })
              }
            />
          </Grid.Column>
        }
      </Grid.Row>
    </Grid>

    {screenLayout === 'table' ?

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('g.id')}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t('s.products.product')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.tax')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.ppu')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.tpu')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.pit')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.qty')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.tt')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.t')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.tit')}</Table.HeaderCell>
            {(management || vendor) && <>
              <Table.HeaderCell textAlign='right'>{t('s.orders.sc')}</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>{t('s.orders.vs')}</Table.HeaderCell>
              {(management && editMode) ? 
                <Table.HeaderCell width={2} textAlign='right'>
                  {t('g.control')}
                </Table.HeaderCell>
              : 
                <Table.HeaderCell width={2} textAlign='right'>
                  {t('s.orders.fulfilment')}
                </Table.HeaderCell>
              }
              
            </>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_renderProducts()}
        </Table.Body>
      </Table>
    :
      _renderProducts()
    }

    {_renderCashCollectingMessage()}
            
    {_renderCompleteDeliveryButton()}

    <Divider hidden />
            
    {management && <>
    <Header as='h3' content={t('s.payments.title')} />

    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('g.uuid')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.status')}</Table.HeaderCell>
          <Table.HeaderCell>{t('s.orders.amount')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.createdAt')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_renderPaymentHistory()}
      </Table.Body>
    </Table>

    <Divider hidden /> 

    <Segment color='red'>
      <Header
        as='h3'
        content={`${t('g.delete')} ${t('s.orders.order')}`}
      />
      
      <div>{t('g.processIsNotReversable')}</div>

      <Divider hidden /> 
      <div>
        <Button
          icon='trash'
          color='red'
          content={t('g.delete')}
          onClick={() => setModal({
            visible: true,
            title: t("g.areYouSure"),
            message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
            actions: [
              {
                key: 0,
                content: t("g.cancel"),
                onClick: () => null
              },
              {
                key: 1,
                content: t("g.delete"),
                negative: true,
                onClick: () => {
                  deleteOrder();
                }
              }
            ]
          }) }
        />
      </div>
    </Segment>
    </> }
  </InternalPage>
}

export default OrderDetails;