
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { 
  Button, 
  Menu, 
  Grid, 
  Table, 
  Input, 
  Header, 
  Loader, 
  Icon, 
  Dropdown,
  Modal, 
  Form, 
  Container,
  Segment,
  Divider,
  Popup
} from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import ReeLaAPI from '../util/reela_lib';
import moment from "moment";
import { formatDateTime, formatDate, URLQuery } from '../util/tools';

// import config
import { config } from '../config';
import render_locals from "../util/funcs/render_locals";

const OrdersListing = (props:any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [screenLayout, setScreenLayout] = useState<'table'|'cards'|null>(null);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [orders, setOrders] = useState<Array<any>|null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<string|null>(null);
  const [orderDirection, setOrderDirection] = useState<string|null>(null);
  const [paid, setPaid] = useState<string|null>(null);
  const [status, setStatus] = useState<string|null>(null);
  const [fromDate, setFromDate] = useState<string|null>();
  const [toDate, setToDate] = useState<string|null>();
  const [driverId, setDriverId] = useState<string|null>();
  const [registrarId, setRegistrarId] = useState<string|null>();
  const [tableOrderDirection, setTableOrderDirection] = useState<any>(null);
  const [resPerPage, setResPerPage] = useState<number>(15);
  const [vendorId, setVendorId] = useState<string|null>(null);
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;
  let driver:boolean = false;
  let vendor:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');

    if(profile.type === 'management') management = true;
    if(profile.type === 'driver') driver = true;
    if(profile.type === 'vendor') vendor = true;
  }
  catch(e) {
    if(process.env.NODE_MODE === 'development') console.warn('Error parsing the profile', e);
  }

  useEffect(() => {
    // set default view
    if(!screenLayout) {
      if(window.innerWidth <= 768) setScreenLayout('cards');
      else setScreenLayout('table');
    }
    
    var recievedVendorId = URLQuery('vendorId', window);
    setVendorId(recievedVendorId);

    getOrders();
    setOrderBy(null);
    setOrderDirection('ASC');
  }, []);

  // run getPost after searchTerm update
  useEffect( () => {
    getOrders();
    
    if(orderDirection === 'ASC') setTableOrderDirection('ascending')
    else setTableOrderDirection('descending');
  }, [
    searchTerm, 
    resPerPage, 
    currentPage, 
    orderBy, 
    orderDirection, 
    paid, 
    status, 
    driverId, 
    fromDate, 
    toDate,
    registrarId
  ]);

  const getOrders = async () => {
    // get the orders list
    if(loadingOrders) return null
    setLoadingOrders(true);
    setOrders([]);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.page = currentPage;
    reelaApi.resPerPage = resPerPage;
    reelaApi.searchTerm = searchTerm;
    reelaApi.orderBy = orderBy;
    reelaApi.orderDirection = orderDirection;
    reelaApi.paid = paid;

    reelaApi.status = status;
    reelaApi.fromDate = fromDate;
    reelaApi.toDate = toDate;
    reelaApi.driverId = driverId;
    reelaApi.registrarId = registrarId;

    var res:any = await reelaApi.getOrders();
    setLoadingOrders(false);

    if(res && res.data) {
      setOrders(res.data);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: reelaApi.getErrorsString(),
      actions: [{ content: t('g.ok') }]
    })
  }

  const changeSort = (columnName:any) => {
    setOrderBy(columnName);
    setOrderDirection(orderDirection === 'ASC' ? 'DESC': 'ASC');
  }

  const _renderOrders = () => {
    // render the table body
    if(orders === null) return null;
    if(orders.length < 1) return null;

    var render:Array<any> = [];
    var reelaApi = new ReeLaAPI;

    orders.map( (order:any, key) => {
      var customerDetails:any = order.customer_details;
      
      if(typeof customerDetails === 'string') {
        try {
          customerDetails = JSON.parse(customerDetails);
          customerDetails = render_locals.renderLocal(
            i18n.language, 
            customerDetails.name_local
          );
        }
        catch(e) {
          if(process.env.NODE_MODE === 'development') console.warn(e);
          customerDetails = '/** Error';
        }
      }
      else {
        // organize the customer information for rendering
        customerDetails = customerDetails?.name_local?.en
      }

      var paid = t("g.yes");
      if(order.paid === 'n') paid = t("g.no");

      if(screenLayout === 'table') {
        render.push(
          <Table.Row 
            key={key} 
            onClick={() => navigate('/orders/show/' + order.uuid)}
          >
            <Table.Cell>
              #{order.uuid.substring(order.uuid.length-10)}
            </Table.Cell>
            {management && <>
              <Table.Cell>
                {`${order.registrar_id || ""} | ${order.registrar_first_name || ""} ${order.registrar_last_name || ""}`}
              </Table.Cell>
              <Table.Cell>
                {order.driver_id}
              </Table.Cell>
              </>
            }
            <Table.Cell>
              {customerDetails}
            </Table.Cell>
            <Table.Cell>
              ({order.delivery_shift}) {order.delivery_date && formatDate(order.delivery_date)}
            </Table.Cell>
            <Table.Cell>
              {order.sub_total.toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}
            </Table.Cell>
            <Table.Cell>
              {order.shipping_total.toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}
            </Table.Cell>
            <Table.Cell>
              {order.service_fee.toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}
            </Table.Cell>
            <Table.Cell>
              {order.house_tax_total.toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}
            </Table.Cell>
            <Table.Cell>
              {order.grand_total.toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}
            </Table.Cell>
            <Table.Cell style={{textTransform: 'capitalize'}}>
              {paid}
            </Table.Cell>
            <Table.Cell>
              <div>{formatDateTime(order.created_at)}</div>
              <div style={{textTransform: 'capitalize'}}>
                {t(`s.orders.status.${order.status}`)}
              </div>
            </Table.Cell>
          </Table.Row>
        );
      }

      else {
        render.push(<>
          <Divider hidden />
          <Segment 
            color="blue"
            onClick={() => navigate('/orders/show/' + order.uuid)}
          >
            <Grid columns={2}>
              <Grid.Row width='equal'>
                <Grid.Column>
                  <div style={{fontWeight: 'bold'}}>
                    #{order.uuid.substring(order.uuid.length-10)}
                  </div>
                  <div>{order.status}</div>
                </Grid.Column>
                <Grid.Column>
                  <div><strong>{t('s.orders.paymentMethod')}</strong></div>
                  <div>{order.payment_method}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row width='equal'>
                <Grid.Column>
                  <div><strong>{t('s.orders.deliveryDate')}</strong></div>
                  <div>
                    {order.delivery_date &&
                    moment(order.delivery_date).format('DD/MM/YYYY') }
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div><strong>{t('s.orders.deliveryShift')}</strong></div>
                  <div>{order.delivery_shift}</div>
                </Grid.Column>
              </Grid.Row>
              {management && <>
                <Grid.Row width='equal'>
                  <Grid.Column>
                    <div><strong>{t('g.driver')} {t('g.id')}</strong></div>
                    <div>{order.driver_id}</div>
                  </Grid.Column>
                  <Grid.Column>
                    <div><strong>{t('g.registrar')}</strong></div>
                    <div>{`${order.registrar_id || ""} | ${order.registrar_first_name || ""} ${order.registrar_last_name || ""}`}</div>
                  </Grid.Column>
                </Grid.Row>
              </>}
              <Grid.Row width='equal'>
                <Grid.Column>
                  <div><strong>{t('g.paid')}</strong></div>
                  <div>{paid}</div>
                </Grid.Column>
                <Grid.Column>
                  <div><strong>{t('g.shipping')}</strong></div>
                  <div>{order.shipping_total.toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row width='equal'>
                <Grid.Column>
                  <div><strong>{t('s.orders.houseTax')}</strong></div>
                  <div>{order.house_tax_total.toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}</div>
                </Grid.Column>
                <Grid.Column>
                  <div><strong>{t('s.orders.serviceFee')}</strong></div>
                  <div>{order.service_fee.toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row width='equal'>
                <Grid.Column>
                  <div><strong>{t('s.orders.subTotal')}</strong></div>
                  <div>{order.sub_total.toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}</div>
                </Grid.Column>
                <Grid.Column>
                  <div><strong>{t('s.orders.grandTotal')}</strong></div>
                  <div>{order.grand_total.toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}</div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </>)
      }
    });

    return <>{render}</>
  }

  const _renderLoadingRow = () => {
    if(!loadingOrders) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  const _renderFilters = () => {
    return (<>
      <Grid stackable>
        <Grid.Row width={16} only='mobile tablet'>
          <Grid.Column>
            <Button
              fluid
              secondary
              content={t('g.menu')}
              onClick={() => setToggleMenu(!toggleMenu)}
            />
            </Grid.Column>
        </Grid.Row >
        <Divider hidden />
        <Grid.Row columns={4} only={!toggleMenu ? 'computer widescreen large screen' : 'mobile tablet'}>
          <Grid.Column>
            <Input
              fluid
              icon={{ name: 'search', link: true }}
              placeholder={t('g.search')}
              value={searchTerm}
              onChange={(e, { value }) => {
                setSearchTerm(value)
                
                if(value.length > 3 || !value) getOrders();
              }}
            />
          </Grid.Column>
          {!vendorId ? 
            <>
              <Grid.Column>
                <Dropdown 
                  placeholder={t('g.paid') || 'Paid'}
                  fluid
                  selection
                  clearable
                  options={[
                    {key: 0, value: 'y', text: t('g.paid')},
                    {key: 1, value: 'n', text: t('g.unpaid')},
                  ]}
                  onChange={(e, {value}) => {
                    setPaid(value?.toString()||null);
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <Popup 
                  trigger={
                    <Input
                      fluid
                      icon={'search'}
                      placeholder={t('g.from')}
                      onChange={(e,data) => {
                        if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {
                          setFromDate(data.value)
                        } else {
                          setFromDate(null)
                        }
                      }}
                    />
                  }
                  header='input format'
                  content='example: 2001-09-04'
                  on={'hover'}
                />
              </Grid.Column>
              <Grid.Column>
                <Popup 
                  trigger={
                    <Input
                      fluid
                      icon={'search'}
                      placeholder={t('g.to')}
                      onChange={(e,data) => {
                        if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {
                          setToDate(data.value)
                        } else {
                          setToDate(null)
                        }
                      }}
                    />
                  }
                  header='input format'
                  content='example: 2001-09-04'
                  on={'hover'}
                />
              </Grid.Column>
            </>
          : null }
        </Grid.Row>
        {!vendorId ? <>
          <Grid.Row columns={4} only={!toggleMenu ? 'computer widescreen large screen' : 'mobile tablet'}>
            <Grid.Column>
              <Dropdown 
                placeholder={t('g.status') || 'Status'}
                fluid
                selection
                clearable
                options={[
                  {key: 0, value: 'new', text: t('s.orders.status.new')},
                  {key: 1, value: 'preparing', text: t('s.orders.status.preparing')},
                  {key: 2, value: 'dispatched', text: t('s.orders.status.dispatched')},
                  {key: 3, value: 'in route', text: t('s.orders.status.inRoute')},
                  {key: 4, value: 'delivered', text: t('s.orders.status.delivered')},
                  {key: 5, value: 'cancelled', text: t('s.orders.status.cancelled')},
                ]}status
                onChange={(e, {value}) => {
                  setStatus(value?.toString()||null);
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                fluid
                icon={{ name: 'search', link: true }}
                placeholder={`${t('g.driver')} ${t('g.id')}`}
                value={driverId}
                onChange={(e, { value }) => {
                  setDriverId(value)
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                fluid
                icon={{ name: 'search', link: true }}
                placeholder={`${t('g.registrar')} ${t('g.id')}`}
                value={registrarId}
                onChange={(e, { value }) => {
                  setRegistrarId(value)
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </> : null}
      </Grid>
      </>
    );
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1'>
      {t('s.orders.title')}
      <Header.Subheader>{t('s.orders.listingDescription')}</Header.Subheader>
    </Header>

    <Grid stackable>
      <Grid.Row width={16} only='mobile tablet'>
        <Grid.Column>
          <Button
            fluid
            secondary
            content={t('g.menu')}
            onClick={() => setToggleMenu(!toggleMenu)}
          />
        </Grid.Column>
      </Grid.Row >
      
      <Divider hidden />

      <Grid.Row columns={4}>
        <Grid.Column>
          <Input
            fluid
            icon={{ name: 'search', link: true }}
            placeholder={t('g.search')}
            value={searchTerm}
            onChange={(e, { value }) => {
              setSearchTerm(value)
              
              if(value.length > 3 || !value) getOrders();
            }}
          />
        </Grid.Column>
        {!vendorId ? 
          <>
            <Grid.Column>
              <Dropdown 
                placeholder={t('g.paid') || 'Paid'}
                fluid
                selection
                clearable
                options={[
                  {key: 0, value: 'y', text: t('g.paid')},
                  {key: 1, value: 'n', text: t('g.unpaid')},
                ]}
                onChange={(e, {value}) => {
                  setPaid(value?.toString()||null);
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Popup 
                trigger={
                  <Input
                    fluid
                    icon={'search'}
                    placeholder={t('g.from')}
                    onChange={(e,data) => {
                      if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {
                        setFromDate(data.value)
                      } else {
                        setFromDate(null)
                      }
                    }}
                  />
                }
                header='input format'
                content='example: 2001-09-04'
                on={'hover'}
              />
            </Grid.Column>
            <Grid.Column>
              <Popup 
                trigger={
                  <Input
                    fluid
                    icon={'search'}
                    placeholder={t('g.to')}
                    onChange={(e,data) => {
                      if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {
                        setToDate(data.value)
                      } else {
                        setToDate(null)
                      }
                    }}
                  />
                }
                header='input format'
                content='example: 2001-09-04'
                on={'hover'}
              />
            </Grid.Column>
          </>
        : null }
      </Grid.Row>
      {!vendorId ? <>
        <Grid.Row columns={4} only={!toggleMenu ? 'computer widescreen large screen' : 'mobile tablet'}>
          <Grid.Column>
            <Dropdown 
              placeholder={t('g.status') || 'Status'}
              fluid
              selection
              clearable
              options={[
                {key: 0, value: 'new', text: t('s.orders.status.new')},
                {key: 1, value: 'preparing', text: t('s.orders.status.preparing')},
                {key: 2, value: 'dispatched', text: t('s.orders.status.dispatched')},
                {key: 3, value: 'in route', text: t('s.orders.status.inRoute')},
                {key: 4, value: 'delivered', text: t('s.orders.status.delivered')},
                {key: 5, value: 'cancelled', text: t('s.orders.status.cancelled')},
              ]}status
              onChange={(e, {value}) => {
                setStatus(value?.toString()||null);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Input
              fluid
              icon={{ name: 'search', link: true }}
              placeholder={`${t('g.driver')} ${t('g.id')}`}
              value={driverId}
              onChange={(e, { value }) => {
                setDriverId(value)
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Input
              fluid
              icon={{ name: 'search', link: true }}
              placeholder={`${t('g.registrar')} ${t('g.id')}`}
              value={registrarId}
              onChange={(e, { value }) => {
                setRegistrarId(value)
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </> : null}
    </Grid>

    <Grid stackable columns={3}>
      {management ?
        <Grid.Column width={6}>
          <Button
            labelPosition='left'
            icon='left chevron'
            content={t('g.back')}
            onClick={() => navigate('/')} 
          />
        </Grid.Column>
      : null}
      <Grid.Column width={4}>
        <Menu secondary position='right'>
        <Menu.Menu >
          <Menu.Item
            icon='refresh'
            name={t('g.refresh') || 'Refresh'}
            onClick={() => getOrders()}
          />
        </Menu.Menu>
        <Menu.Menu >
          <Menu.Item
            icon='table'
            name={t('g.tableView') || 'Table View'}
            onClick={() => setScreenLayout('table')}
          />
        </Menu.Menu>
        <Menu.Menu >
          <Menu.Item
            icon='list layout'
            name={t('g.listView') || 'List View'}
            onClick={() => setScreenLayout('cards')}
          />
        </Menu.Menu>
        </Menu>
      </Grid.Column>
      <Grid.Column width={6} >
        {management ? 
          <Button
            floated='right'
            primary
            labelPosition='right'
            icon='external alternate'
            content={t('s.export.export')}
            onClick={() => navigate('orders/export')} 
          />
        : null}
      </Grid.Column>
    </Grid>

    {screenLayout === 'table' &&
    <Table color='violet' striped selectable sortable >
      <Table.Header>
        <Table.HeaderCell width={1}>{t('g.uuid')}</Table.HeaderCell>
        {management && <>
          <Table.HeaderCell width={2}>{t('g.registrar')}</Table.HeaderCell>
          <Table.HeaderCell width={1}>{t('s.drivers.driver')}</Table.HeaderCell>
        </>}
        <Table.HeaderCell width={3}>{t('g.customer')}</Table.HeaderCell>
        <Table.HeaderCell
          width={1}
          onClick={() => changeSort('delivery_date')}
          sorted={orderBy === 'delivery_date' ? tableOrderDirection : null}
        >{t('g.delivery')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('s.orders.subTotal')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('g.shipping')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('s.orders.serviceFee')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('s.orders.houseTax')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('s.orders.grandTotal')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('g.paid')}</Table.HeaderCell>
        <Table.HeaderCell 
          width={2}
          onClick={() => changeSort('created_at')}
          sorted={orderBy === 'created_at' ? tableOrderDirection : null}
        >{t('g.createdAt')}</Table.HeaderCell>
      </Table.Header>
      {_renderOrders()}
      {_renderLoadingRow()}
    </Table> }
    
    {screenLayout === 'cards' &&
    <div>
      {_renderOrders()}
    </div> }
    
    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>
  </InternalPage>
}

export default OrdersListing;